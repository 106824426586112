import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { Context } from '../context'
import ServiceAuth from '../services/service_auth'
import ServiceQuery from '../services/service_query'
import { Navigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import { AuthContext } from '../Context/authProvider'

function AccueilClient () {


  // const user = ServiceAuth.getCurrentUser()
  const [queryError, setQueryError] = useState("")
  const [querySuccess, setQuerySuccess] = useState("")
  const {auth, authDispatch} = useContext(AuthContext)

  const handleSubmit = async (event) =>{
    event.preventDefault();
    // console.log('value',event.target[0].value)
    let emailValue = event.target[0].value
    try {
      if(emailValue !== auth?.mail){
        var returnService = await ServiceQuery.postNewEmail(emailValue)
        authDispatch({type:'updateEmail', payload: {emailValue}})
        setQuerySuccess(returnService)

      }      
    } catch (error) {
        setQueryError(error)
    }

  }
  
  return (
    <div className="list-group">
      <div className="alert alert-info mx-5 mb-0" style={{}}>En cas de commande, afin de planifier nos livraisons pendant l’été 2024, pensez à nous transmettre vos dates de fermeture pour les mois de juillet – août par mail à votre commercial et/ou ADV</div>
        <h1 className="text-center mt-2">Espace Pro</h1>
        <div className='row'>
          <div className='col'>
            <form id="formAccountDetails" onSubmit={handleSubmit}>
              <h3 className=''>Détail du compte</h3>

              <ul className="list-group list-group-flush">
                <li className="list-group-item"> Société: {auth?.businessName}</li>
                <li className="list-group-item"> Code client: {auth?.numSage}</li>
                <li className="list-group-item"> Identifiant: {auth?.name}</li>
                <li className="list-group-item"> Nom complet: {auth?.fullName}</li>
                <li className="list-group-item">
                Email: <input type="email" id="email" defaultValue={auth?.mail} required/>
                </li>
                {/* <li className="list-group-item"> role: {auth?.role}</li> */}
                
                <li className="list-group-item">
                  <button type="submit" style={{paddingLeft:'1.5em',paddingRight:'1.5em'}}className="btn btn-primary" disabled={''}>Sauvegarder les modifications</button>
                </li>
                <li className="list-group-item">{querySuccess && <div className="alert alert-info">{querySuccess}</div>}</li>
                <li className="list-group-item">{queryError && <div className="alert alert-danger">{queryError}</div>}</li>
              </ul>
            </form>
          </div>
          <div className='col'>
            <h3 className=''>Représentant du compte</h3>
          <ul className="list-group list-group-flush" style={{alignItems: "center"}}>
            <img src={process.env.PUBLIC_URL + '/image_responsable/' + auth?.ownerId + '.jpg'} alt="photo_responsable" width="200px" height="160px" className="d-inline-block align-text-top mt-1" />
              <li className="list-group-item"> nom: {auth?.ownerName}</li>
              <li className="list-group-item"> Titre: {auth?.ownerTitle}</li>
              <li className="list-group-item"> email: {auth?.ownerMail}</li>
              <li className="list-group-item"> téléphone: {auth?.ownerPhone}</li>
            </ul>
          </div>
        </div>
        {/* <div>
          <a href='/OP socotex.png' target='_blank' rel='noreferrer noopener'>
              <img src={process.env.PUBLIC_URL + '/OP socotex.png'} alt="Actualité Socotex" width="fitContent" height="500px"></img>
          </a>
        </div> */}
        <div className="alert alert-secondary mx-5 mb-0" style={{}}>En cas de problème, de question ou pour faire un retour, en lien avec l'espace pro, vous pouvez nous contacter à l'adresse suivante:
          <a href='mailto:flux@socotex.fr' style={{'color':'#6756DA'}}> <u>SUPPORT</u> </a><br/>
          Dans le cas d'un problème, nous vous prions de fournir le plus de détails possibles sur ce dernier
          (description du problème, capture d'écran, les étapes qui ont menée à ce problème, etc...)</div>
        
    </div>

  )
}

export default AccueilClient