import { createContext, useEffect, useMemo, useState, useReducer } from "react";
// import ServiceQuery from '../services/service_query'
// import { useLocation} from "react-router-dom";

const initialState = {
  name: "",
  fullName: "",
  role: "",
  mail: "",
  phone: "",
  businessName: "",
  numSage: "",
  ownerName: "",
  ownerMail: "",
  ownerPhone: "",
  ownerTitle: "",
  ownerId:""
}

const reducer = (state,action) => {
    switch(action.type){
      case 'setUser':
        return { ...state, 
          name: action.payload.username, 
          fullName: action.payload.fullName,
          role: action.payload.role,
          mail: action.payload.email, 
          phone: action.payload.phone,
          businessName: action.payload.businessName, 
          numSage: action.payload.numSage,
          ownerName: action.payload.ownerName, 
          ownerMail: action.payload.ownerMail,
          ownerPhone: action.payload.ownerPhone, 
          ownerTitle: action.payload.ownerTitle, 
          ownerId: action.payload.ownerId
        }
      case 'updateUser':
        return {...state, 
          name: action.payload.username, 
          fullName: action.payload.fullName,
          role: action.payload.role,
          mail: action.payload.email, 
          phone: action.payload.phone,
          businessName: action.payload.businessName, 
          numSage: action.payload.numSage,
          ownerName: action.payload.ownerName, 
          ownerMail: action.payload.ownerMail,
          ownerPhone: action.payload.ownerPhone, 
          ownerTitle: action.payload.ownerTitle, 
          ownerId: action.payload.ownerId
        }
        case 'updateEmail':
          return {
            ...state,
            mail: action.payload.emailValue
          }
      case 'logout':
        return {...state,   name: "", fullName: "",
        role: "", mail: "", phone: "", businessName: "", numSage: "", ownerName: "", ownerMail: "", ownerPhone: "", ownerTitle: "", ownerId: "" }
        default: 
            throw Error('Unknown action in context reducer.')
  }
}

const AuthContext = createContext({ context: initialState, authDispatch: () => null });

function AuthProvider({children}) {
    // console.log("refresh AuthContext")
    const [auth, authDispatch] = useReducer(reducer, initialState)

    return (
        <AuthContext.Provider value = {{auth, authDispatch}}>
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider};